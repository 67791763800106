import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';
import './DistillFlow.css'; // Custom styles
import ProductDemo from './ProductDemo.js';
import ProductFeatures from './ProductFeatures.js';
import Benefits from './Benefits.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionButtons from './ActionButtons.js';
import GetInTouch from '../GetInTouch.js';

const DistillFlow = () => {
  return (
      <div>
        <section className="hero">
          <motion.div className="hero-content" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <h1>AI customized for your business</h1>
            <p>Streamline innovation with high-performance, Small Language Models tailored to your industry needs.</p>
            <ActionButtons showGithub={false} />
          </motion.div>
        </section>
        <section className="product-section">
          <motion.div className="product-content" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 2 }}>
          <ProductFeatures />
          </motion.div>
        </section>

        <section className="how-it-works">
        <motion.div className="product-content" initial={{opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 2 }}>
          <ProductDemo />
          </motion.div>
        </section>

        <section className="motivation">
        <motion.div className="product-content" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 2 }}>
          <Benefits />
          </motion.div>
        </section>
        <GetInTouch />
     </div>
  );
}

export default DistillFlow;