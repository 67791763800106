import React, { useEffect, useRef, useState } from "react";
import './ProductDemo.css';

const ProductDemo = () => {
  const demoRef = useRef(null);
  const videoRef = useRef(null);  // Reference for the video element
  const [inFocus, setInFocus] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInFocus(true);  // Demo is in focus, start it
        } else {
          setInFocus(false);  // Demo is out of focus, pause or reset
        }
      },
      { threshold: 0.5 }  // Adjust threshold based on how much visibility you want
    );

    if (demoRef.current) {
      observer.observe(demoRef.current);
    }

    return () => {
      if (demoRef.current) {
        observer.unobserve(demoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (inFocus && videoRef.current) {
      videoRef.current.play();  // Play video when in focus
    } else if (videoRef.current) {
      videoRef.current.pause();  // Pause video when out of focus
    }
  }, [inFocus]);

  return (
    <div className="product-demo" ref={demoRef}>
      <h2 className="feature-header">From LLM to SLM with just a few clicks</h2>

      <div className="product-demo-container">
        <div className="steps">
          <div className="step-box">Step 1: Define your teacher model</div>
          <div className="step-box">Step 2: Define your student model</div>
          <div className="step-box">Step 3: Provide your fine-tuning data</div>
          <div className="step-box">Step 4: Hit Submit</div>
        </div>
      </div>
    </div>
  );
};

export default ProductDemo;
