import React, { useState, useEffect, useRef } from 'react';
import './GetInTouch.css'; // Make sure to link the CSS file

const GetInTouch = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isSent, setIsSent] = useState(false);
  const formRef = useRef(null);

  // Intersection Observer to trigger animation when form comes into view
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    });

    if (formRef.current) {
      observer.observe(formRef.current);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('https://formspree.io/f/mblrepzv', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        message: formData.message,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setIsSent(true);
          setFormData({ name: '', email: '', message: '' });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <section id="get-in-touch" className="get-in-touch">
      <h2 className="feature-header">Get In Touch</h2>
      <div ref={formRef} className="form-container">
        <form className="get-in-touch-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <textarea
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" className="submit-button">
            {isSent ? 'Message Sent!' : 'Send Message'}
          </button>
        </form>
      </div>
    </section>
  );
};

export default GetInTouch;
