import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="social-media">
        <a href="https://www.linkedin.com/company/horus-ai-labs"><FontAwesomeIcon icon={faLinkedin} /></a>
        <a href="mailto:horusailabs@gmail.com"><FontAwesomeIcon icon={faEnvelope} /></a>
      </div>
      <p>&copy; 2024 Horus AI Labs. All Rights Reserved.</p>
    </footer>
  );
};