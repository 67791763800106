import './ActionButtons.css';
import { FaGithub } from 'react-icons/fa';

const ActionButtons = (props) => {

  return (
    <div className="action-buttons">
      {props.showGithub &&  (
          <a href="https://github.com/horus-ai-labs/DistillFlow/" target="_blank" rel="noopener noreferrer" style={styles.iconLink}>
            <FaGithub style={styles.icon}  />
          </a>
      )}
      <a href="https://calendar.app.google/6ppPSqrEcMNAnq387" target="_blank" rel="noopener noreferrer">
        <button className="demo-btn">Book a Demo</button>
      </a>
      <a href="#get-in-touch">
        <button className="playground-btn">Get In Touch</button>
      </a>
    </div>
  );
}

const styles = {
  iconLink: {
    color: '#fff',
    textDecoration: 'none',
  },
  icon: {
    padding: '0px 16px',
    fontSize: '40px',
    marginBottom: '-10px',
  },
};

export default ActionButtons;