import React, { useEffect } from 'react';
import { FaCogs, FaBolt, FaBalanceScale, FaChartPie, FaShieldAlt, FaDollarSign } from 'react-icons/fa';
import './Benefits.css';
import { useMediaQuery } from 'react-responsive';


const Benefits = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
        }
      });
    });

    const textBlocks = document.querySelectorAll('.benefit-text-block, .benefit-icon, .line');
    textBlocks.forEach((textBlock) => observer.observe(textBlock));

    return () => {
      textBlocks.forEach((textBlock) => observer.unobserve(textBlock));
    };
  }, []);

  return (
    <div className="business-benefits-line">
          <h2 className="feature-header">How DistillFlow helps your Business</h2>

          <div className="benefit-row">
            <div className="icon-line">
              <FaBolt className="benefit-icon" />
              {!isMobile && (<div className="line"></div>)}
            </div>
            <div className="benefit-text-block">
              <h3>Accelerated Innovation</h3>
              <p>Quickly deploy distilled models tailored for your industry, enabling faster experimentation and reducing time-to-market.</p>
            </div>
          </div>

          <div className="benefit-row">
            <div className="icon-line">
              <FaBalanceScale className="benefit-icon" />
              {!isMobile && (<div className="line"></div>)}
            </div>
            <div className="benefit-text-block">
              <h3>Cost Efficiency</h3>
              <p>Save on computational costs by utilizing smaller, efficient models, making high-performance AI accessible without hefty resources.</p>
            </div>
          </div>

          <div className="benefit-row">
            <div className="icon-line">
              <FaShieldAlt className="benefit-icon" />
              {!isMobile && (<div className="line"></div>)}
            </div>
            <div className="benefit-text-block">
              <h3>Enhanced Data Security</h3>
              <p>Deploy on-premises to keep sensitive data secure, ensuring compliance with stringent industry standards.</p>
            </div>
          </div>

          <div className="benefit-row">
            <div className="icon-line">
              <FaChartPie className="benefit-icon" />
              {!isMobile && (<div className="line"></div>)}
            </div>
            <div className="benefit-text-block">
              <h3>Scalable Performance</h3>
              <p>Support growth by scaling models effortlessly to meet demand, adaptable for both small teams and large enterprises.</p>
            </div>
          </div>

          <div className="benefit-row">
            <div className="icon-line">
              <FaDollarSign className="benefit-icon" />
            </div>
            <div className="benefit-text-block">
              <h3>Improved ROI</h3>
              <p>Lower model training costs while boosting productivity, maximizing your AI investment with targeted performance gains.</p>
            </div>
          </div>
        </div>
  );
};

export default Benefits;
