import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';
import './HomePage.css'; // Custom styles
import ProductDemo from './distill/ProductDemo.js';
import ProductFeatures from './distill/ProductFeatures.js';
import SurveillanceBenefits from './distill/Benefits.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionButtons from './ActionButtons.js';
import GetInTouch from './GetInTouch.js';

const HomePage = () => {
  return (
      <div>
        <section className="hero">
          <motion.div className="hero-content" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <h1>Effortless AI, Built for Impact</h1>
            <p>Empower your business with streamlined, scalable models that deliver speed, precision, and adaptability across industries.</p>
            <ActionButtons showGithub={false} />
          </motion.div>
        </section>
        <GetInTouch />
     </div>
  );
}

export default HomePage;