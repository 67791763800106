import React, { useEffect, useState, useRef } from 'react';
import { FaClock, FaShieldAlt, FaChartBar, FaLayerGroup, FaCog, FaChartLine, FaAdjust, FaNetworkWired } from 'react-icons/fa';
import './ProductFeatures.css';

const ProductFeatures = () => {
  useEffect(() => {
      const revealElements = document.querySelectorAll('.reveal');

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible');
              observer.unobserve(entry.target); // Stops observing once the animation is triggered
            }
          });
      }, { threshold: 0.2 }); // Triggers when 20% of the card is visible

      revealElements.forEach(element => {
        observer.observe(element);
      });

      return () => {
        revealElements.forEach(element => {
          observer.unobserve(element);
        });
      };
    }, []);

  return (
    <div className="product-features">
      <h2 className="feature-header">Powerful features that set us apart</h2>
      <div className="feature-cards-container">
        <div className="feature-card reveal">
          <FaCog className="feature-icon" />
          <h3>Flexible Distillation Pipeline</h3>
          <p>Seamlessly add and configure steps in the distillation workflow to create smaller, efficient models tailored to specific needs.</p>
        </div>
        <div className="feature-card reveal">
          <FaAdjust className="feature-icon" />
          <h3>Domain-Specific Fine-Tuning</h3>
          <p>Customize models with ease for different domains, ensuring performance aligns with specialized business requirements.</p>
        </div>
        <div className="feature-card reveal">
          <FaChartLine className="feature-icon" />
          <h3>Efficient Model Quantization</h3>
          <p>Run quantization techniques to reduce model size without compromising performance, making deployment lighter and faster.</p>
        </div>
        <div className="feature-card reveal">
          <FaNetworkWired className="feature-icon" />
          <h3>Seamless Evaluation Integration</h3>
          <p>Integrate with the LM Evaluation Harness for automated benchmarking and evaluate models on both standard and custom metrics.</p>
        </div>
        <div className="feature-card reveal">
          <FaShieldAlt className="feature-icon" />
          <h3>Data Security & On-Prem Deployment</h3>
          <p>Deploy on-premises or securely in the cloud, ensuring sensitive data stays protected to meet stringent compliance standards.</p>
        </div>
        <div className="feature-card reveal">
          <FaLayerGroup className="feature-icon" />
          <h3>Scalable Model Deployment</h3>
          <p>Effortlessly scale to handle high model volumes, empowering you to serve diverse business needs from a unified platform.</p>
        </div>
      </div>
    </div>
  );
};

export default ProductFeatures;
